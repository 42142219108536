.filterBox {
  background-color: #ffffff !important;
  border-radius: 10px;
  padding: 10px 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 9999;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgb(123 123 123 / 25%);
  width: 100%;
  max-width: fit-content;
}

.CrossIcon {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.cancelIcon svg {
    color: #E52628;
    font-weight: 700;
    background: #e9cec894;
    padding: 6px;
    width: 32px;
    height: 34px;
    border-radius: 50%;
    margin-left: 15px;
    cursor: pointer;
    font-size: 0px;
    margin-top: 2px;
}


