:root {
    --danger: #fa626b;
}

.btn {
    background-color: #E52628;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
    height: 40px;
}

.btn:hover {
    background-color: #E52628 !important;
    color: #FFFFFF !important;
}

.btn:active {
    background-color: #E52628 !important;
}

.btn:focus {
    outline: 0;
    box-shadow: none !important;
}