.footer {
  background: #000000;
  color: #ffffff;
}

.footer .footerContent {
  padding-top: 50px;
  padding-bottom: 34px;
}



/* .footer .container {
  max-width: 1170px;
  padding-top: 100px;
}

.footer .container p {
  padding-top: 15px;
} */

.signAfricaImg {
  margin-top: 18px;
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
}

.signAfricaImg img {
  width: 100%;
  max-width: 300px;
}

.beWithTxt {
  display: flex;
  justify-content: center;
  color: #E52628;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 15px;
}

.socialIcon img {
  width: 40px;
  margin-right: 6px;
  margin-bottom: 10px;
  cursor: pointer;
}

.signAfricaTxt {
  text-align: center;
  font-size: 15px;
  margin-bottom: 20px;
}

.linkHeading {
  margin-top: 18px;
}

.linkHeading h6 {
  font-weight: 500;
  font-size: 9px;
  letter-spacing: -0.19px;
  color: #ffffff;
}

.linkNames {
  margin-top: 36px;
}

.linkNames a {
  text-decoration: none;
}

.linkNames h6 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 12px;
}

.newsletterImg {
  display: flex;
  justify-content: center;
}

.linkHeadingWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.linkHeadingWrapper h5 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 18px;
}

.appStoresImg {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 8px;
}

.appStoresImg img {
  width: 100%;
  max-width: 180px;
  margin: -2px 0px;
}

.footer .footerEndTxt {
  margin-top: 70px;
  text-align: center;
  font-size: 14px;
}

.footer .footerEndTxt a{
  font-size: 14px;
    color: #ffffff;
    text-decoration: none;
}

.iconWrapper {
  display: flex;
  justify-content: center;
}

.socialIcon {
  display: flex;
  flex-wrap: wrap;
  max-width: 138px;
  width: 100%;
}

.publishInfoWrapper p {
  margin-bottom: 0px;
  font-weight: 600;
  letter-spacing: 0.75px;
  text-align: center;
}

.publishInfoWrapper span {
  font-size: 14px;
  display: block;
  margin-top: 12px;
  text-align: center;

}

@media screen and (min-width:1200px) {
  .footer .footerOverRide {
    max-width: 1070px !important;
    padding: 0 !important;
  }

  .footer .footerOverRide .row {
    margin-right: 16px;
    margin-left: 16px;
  }

  .footer .footerOverRide .col-lg-4 {
    width: 316px;
    margin-left: 15px;
    padding: 0px 8px !important;
    margin-right: 15px;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1199px) {
  .footer .footerOverRide {
    max-width: 1070px !important;
    padding: 0 !important;
  }

  .footer .footerOverRide .row {
    margin-right: 16px;
    margin-left: 16px;
  }

  .footer .footerOverRide .col-lg-4 {
    width: 316px;
    margin-left: 15px;
    padding: 0px !important;
    margin-right: 15px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1079px) {
  .footer .footerOverRide .col-lg-4 {
    width: 316px;
    margin-left: 2px;
    padding: 0px !important;
    margin-right: 2px;
  }
}

@media screen and (min-width: 880px) and (max-width: 991px) {
  .footer .footerOverRide {
    max-width: 840px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 879px) {
  .footer .footerOverRide {
    max-width: 720px !important;
  }
}

@media screen and (min-width: 676px) and (max-width: 767px) {
  .footer .footerOverRide {
    max-width: 645px !important;
  }

  .publishInfoWrapper p {
    font-size: 14px;
    letter-spacing: 0;
  }
}

@media screen and (min-width: 480px) and (max-width: 675px) {
  .footer .footerOverRide {
    max-width: 460px !important;
  }

  .footer .footerOverRide .col-sm-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .linkHeading {
    margin-top: 25px;
  }
  .footer .footerEndTxt {
    margin-top: 40px;
  }
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .footer .footerOverRide {
    max-width: 320px !important;
  }

  .footer .footerOverRide .col-sm-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .linkHeading {
    margin-top: 25px;
  }
  .footer .footerEndTxt {
    margin-top: 40px;
  }
}