.headerFeature {
  display: flex;
  background: #ffffff;
  opacity: 0.7;
  margin-top: 15px;
  padding-right: 25px !important;
  padding-left: 25px !important;
  justify-content: right;
  width: 100%;
  max-width: fit-content;
  /* max-width: 352px; */
  height: 39px !important;
}

.headerFeature h5 {
  font-weight: 500;
  font-size: 15px;
  padding-top: 10px;
  color: #474747;
  letter-spacing: 0.2px;
  cursor: pointer;
}

.headerFeature span {
  color: #E52628;
  font-weight: 600;
  padding-top: 8px;
  padding-right: 10px;
  padding-left: 10px;
}

.infoIcon svg {
  margin-top: 7px;
  margin-left: 5px;
  cursor: pointer;
}

.infoIcon svg:focus {
  outline: none;
}

@media only screen and (min-width: 338px) and (max-width: 379px) {
  .headerAfterProfile {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .headerAfterProfile h5 {
    font-size: 14px !important;
    letter-spacing: unset !important;
    padding-top: 11px;
  }
}

@media only screen and (min-width: 328px) and (max-width: 337px) {
  .headerAfterProfile {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .headerAfterProfile h5 {
    font-size: 14px !important;
    letter-spacing: unset !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 327px) {
  .headerAfterProfile {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }

  .headerAfterProfile h5 {
    font-size: 14px !important;
    letter-spacing: unset !important;
    padding-top: 11px;
  }
}

@media screen and (max-width: 500px) {
  .tooltipClass {
    width: 300px !important;
  }
}

@media screen and (max-width: 991px) {
  .headerFeature {
    /* max-width: inherit; */
    justify-content: left;
  }
}

@media screen and (min-width: 320px) and (max-width: 328px) {
  @-moz-document url-prefix() {
    .headerAfterProfile h5 {
      font-size: 13px !important;
      letter-spacing: unset !important;
      padding-top: 12px;
    }
  }
}

@media screen and (min-width: 339px) and (max-width: 380px) {
  @-moz-document url-prefix() {
    .headerAfterProfile h5 {
      font-size: 14px !important;
      letter-spacing: unset !important;
      padding-top: 11px !important;
    }
  }
}

@media screen and (min-width: 338px) and (max-width: 338px) {
  @-moz-document url-prefix() {
    .headerAfterProfile h5 {
      font-size: 13px !important;
      letter-spacing: unset !important;
      padding-top: 12px !important;
    }
  }
}

@media screen and (min-width: 992px) {
  .headerAfterProfile {
    justify-content: center;
  }

  .headerAfterProfile h5 {
    font-size: 14px !important;
    padding-top: 11px !important;
  }
}

@media only screen and (max-width: 319px) {
  .headerAfterProfile {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }

  .headerAfterProfile h5 {
    font-size: 13px !important;
    letter-spacing: unset !important;
    padding-top: 12px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .headerAfterProfile {
    padding-right: 11px !important;
    padding-left: 11px !important;
    max-width: 352px !important;
  }

  .headerAfterProfile h5 {
    font-size: 13px !important;
    padding-top: 11px !important;
  }

  .headerFeature {
    max-width: 352px !important;
  }
}


@media only screen and (min-width: 1200px) {
  .headerFeature {
    max-width: 352px !important;
  }
}