.productModule {
  margin-bottom: 30px;
  margin-top: 23px;
}

.products {
  background: #ffffff;
  box-shadow: 0px 25px 40px rgb(0 0 0 / 3%);
  border-radius: 6px;
  margin-top: 10px;
}

.products h2 {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #0a0000;
  padding: 15px 10px;
}

.products span {
  color: #E52628;
}

.productslist {
  background: #ffffff;
  box-shadow: 0px 25px 40px rgb(0 0 0 / 3%);
  border-radius: 6px;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.productImg img {
  width: 180px;
  border-radius: 5.75997px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 25%);
}

.productDiscription {
  padding-left: 20px;
  width: 100%;
}

.productDiscription h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.productDiscription p {
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.productDiscription h5 {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0px;
}

.productModule h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.315px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 400px) {
  .productDiscription h3 {
    font-weight: 600;
    font-size: 15px !important;
    line-height: 19px;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 2px;
  }

  .productDiscription p {
    font-weight: 300;
    font-size: 12px !important;
    line-height: 18px;
    color: #000000;
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .productImg img {
    width: 120px;
    height: 113px;
  }
}

/* company text css */
.companyText {
  align-items: center;
  display: flex;
}

.date {
  display: flex;
  align-items: center;
  margin-left: 7px;
  margin-top: -9px;
}

.showDate {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 2px;
}

.date img {
  height: 18.57px;
  margin-right: 6px;
  width: 18.57px;
}

.companyText h2 {
  font-weight: 600;
  color: #fff;
  border-radius: 14px;
  background: linear-gradient(180deg, #f24237 0%, #ea2064 100%);
  width: 100%;
  max-width: 96px;
  height: 28.89px;
  text-align: center;
  padding-top: 5px;
  font-size: 11px;
  line-height: 20px;
}

@media only screen and (min-width: 310px) and (max-width: 339px) {
  .productImg img {
    width: 110px;
    height: 113px;
  }

  .companyText h2 {
    width: 53.63px !important;
    font-size: 8.4378px !important;
    height: 22.89px !important;
    padding-top: 2px !important;
    color: #fff !important;
    font-weight: 600 !important;
  }

  .date img {
    height: 11.57px !important;
    width: 10.57px !important;
    margin-right: 3px !important;
  }

  .showDate {
    font-size: 8.78876px !important;
    line-height: 12px !important;
  }

  .date {
    margin-left: 4px;
  }
}


@media only screen and (min-width: 340px) and (max-width: 365px) {
  .productImg img {
    width: 110px;
    height: 113px;
  }

  .companyText h2 {
    width: 53.63px !important;
    font-size: 8.4378px !important;
    height: 20.89px !important;
    padding-top: 1px !important;
    color: #fff !important;
    font-weight: 600 !important;
  }

  .date img {
    height: 12.57px !important;
    width: 11.57px !important;
    margin-right: 3px !important;
  }

  .showDate {
    font-size: 9.78876px !important;
    line-height: 12px !important;
    margin-top: 2px;
  }

  .date {
    margin-left: 4px;
    margin-top: -9px;
  }
}

@media only screen and (min-width: 366px) and (max-width: 400px) {
  .companyText h2 {
    width: 73.63px !important;
    font-size: 9.4378px !important;
    height: 23.89px !important;
    padding-top: 2px !important;
    color: #fff !important;
    font-weight: 600 !important;
  }

  .date img {
    height: 14.57px;
    width: 14.57px;
    margin-right: 3px;
  }

  .showDate {
    font-size: 10.78876px !important;
    line-height: 12px;
    margin-top: 2px;
  }

  .date {
    margin-top: -9px;
  }
}

@media only screen and (min-width: 401px) and (max-width: 479px) {
  .productImg img {
    width: 135px;
    height: 132px;
  }

  .companyText h2 {
    width: 73.63px !important;
    font-size: 10.4378px !important;
    height: 23.89px !important;
    padding-top: 3px !important;
    color: #fff !important;
    font-weight: 600 !important;
  }

  .date img {
    height: 14.57px;
    width: 14.57px;
    margin-right: 3px;
  }

  .showDate {
    font-size: 12.78876px !important;
    line-height: 12px;
    margin-top: 1px;
  }

  .date {
    margin-top: -9px;
  }
}